<template>
  <div
    :class="{'aspect-[16/9] bg-black bg-cover' : !playerReady}"
    :style="!youtubeId || !playerReady ? {backgroundImage: `url(${posterUrl})`} : {}"
  >
    <ClientOnly v-if="youtubeId">
      <Player
        v-if="!resetInProgress"
        ref="player"
        class="bg-black"
        :class="{'opacity-0': !playerReady}"
        :current-time="currentTime"
        @vm-playing-change="playing = $event"
        @vm-ready="playerReady = true"
        @vm-playback-ended="reset"
      >
        <Youtube
          :key="youtubeId"
          :poster="posterUrl ?? ''"
          :video-id="youtubeId"
        />

        <DefaultUi
          :no-click-to-play="true"
          :no-dbl-click-fullscreen="true"
          :no-controls="true"
        />
        <Controls
          v-if="!playing"
          class="primary-play-control"
          pin="center"
          justify="center"
        >
          <PlaybackControl />
        </Controls>
        <Controls v-if="playing">
          <PlaybackControl />
          <ScrubberControl />
          <VolumeControl />
          <FullscreenControl />
        </Controls>
      </Player>
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import {
  Controls,
  DefaultUi,
  FullscreenControl,
  PlaybackControl,
  Player,
  ScrubberControl,
  VolumeControl,
  Youtube,
} from '@vime/vue-next';

defineProps<{
  posterUrl?: string
  youtubeId: string
}>();

const currentTime = ref(0);
const player = ref();
const playing = ref(false);
const playerReady = ref(false);
const resetInProgress = ref(false);

function reset() {
  playerReady.value = false;
  resetInProgress.value = true;

  nextTick(() => resetInProgress.value = false);
}
</script>

<style scoped>
vm-player[video] {
  --vm-player-theme: theme("colors.primary.DEFAULT");
  --vm-player-border-radius: 0;
  --vm-blocker-z-index: -1; /* Disables the blocker overlay, to allow interaction with the YouTube controls */
}

.primary-play-control {
  --vm-control-bg: #fff;
  --vm-control-border-radius: 100px;
  --vm-control-color: #000;
  --vm-control-padding: 16px;
}
</style>

<style src="@vime/core/themes/default.css" />
